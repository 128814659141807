/* eslint-disable */
import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const GlobalContext = createContext({})

export const GlobalProvider = ({ children }) => {
  const token = 'h72vjlVkHGUN3Se+9yBtdiOpTZFECoi/rxmO2alDuZg='
  const api = 'https://admin.kisdigital.co.uk/api/v1/'

  const navigate = useNavigate()

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const CryptoJS = require('crypto-js')

  const [steps, setSteps] = useState([
    {
      id: 0,
      status: 'in-progress',
      name: 'Start',
      current: true,
    },
    {
      id: 1,
      status: 'incomplete',
      name: 'Your Details',
      current: false,
    },
    {
      id: 2,
      status: 'incomplete',
      name: 'Staff Categories',
      current: false,
    },
    {
      id: 3,
      status: 'incomplete',
      name: 'Your Cover',
      current: false,
    },
    {
      id: 4,
      status: 'incomplete',
      name: 'Additional Information',
      current: false,
    },
    {
      id: 5,
      status: 'incomplete',
      name: 'Complete',
      current: false,
    },
  ])

  const currentdate = new Date()
  const datetime =
    currentdate.getDate() +
    '/' +
    (currentdate.getMonth() + 1) +
    '/' +
    currentdate.getFullYear() +
    ' @ ' +
    currentdate.getHours() +
    ':' +
    currentdate.getMinutes() +
    ':' +
    currentdate.getSeconds()

  const [quote, setQuote] = useState({
    product: 'SAS Policy',
    productMeta: '',
    team: '0',
    auth: {
      id: 0,
      name: '',
      request: '',
      status: '',
    },
    status: 'enquiry',
    type: 'single',
    department: 'SAS',
    owner: 'Online Form',
    referenceNumber: '0',
    schools: [],
    variations: [],
    variationLive: '0',
    MATpolicyManagement: '',
    MATcoverLevel: '',
    absences: [],
    marketing: {
      hearAboutUs: '--',
      hearAboutUsOther: '',
      currentInsurer: '',
      currentPremium: '',
      referralCode: '',
      nahtMember: false,
    },
    nahtMember: false,
    referralCode: '',
    discount: 0,
    discountReason: '',
    loading: 0,
    loadingReason: '',
    matCreated: false,
    meta: {
      startDateTime: datetime,
      endDateTime: '',
      emailText: '',
      quoteText: '',
      notesPrivate: '',
      notesPublic: '',
    },
  })
  const [progress, setProgress] = useState({
    contact_name: '',
    contact_position: '',
    contact_email: '',
    contact_tel: '',
    contact_organisation: '',
    contact_id: '',
    contact_type: 'Trust',
    quoteID: '',
    userID: 0,
    enquiryID: '',
    nahtMember: false,
    callback: {
      callTime: 'am',
      callDate: '',
    },
    marketing: {
      hearAboutUs: '--',
      hearAboutUsOther: '',
      currentInsurer: '',
      currentPremium: '',
      referralCode: '',
      nahtMember: false,
    },
    referralCode: '',
  })

  const [deletedSchools, setDeletedSchools] = useState([])

  const saveProgress = async () => {
    let variations = quote.variations
    variations[0] = quote.schools

    setQuote({ ...quote, variations: variations })

    let quoteJson = JSON.stringify(quote)
    let q = CryptoJS.AES.encrypt(quoteJson.toString(), token)
    localStorage.setItem('quote', q)

    var date = new Date()
    localStorage.setItem('timestamp', date.toString())

    let progressJson = JSON.stringify(progress)
    let p = CryptoJS.AES.encrypt(progressJson.toString(), token)
    localStorage.setItem('progress', p)

    let deletedJson = JSON.stringify(deletedSchools)
    let s = CryptoJS.AES.encrypt(deletedJson.toString(), token)
    localStorage.setItem('deletedSchools', s)

    let stepsJson = JSON.stringify(steps)
    let sv = CryptoJS.AES.encrypt(stepsJson.toString(), token)
    localStorage.setItem('steps', sv)
  }

  const loadProgress = async () => {
    let timestamp = await localStorage.getItem('timestamp')

    if (timestamp) {
      let storedDate = new Date(timestamp)
      let currentDate = new Date()

      let Difference_In_Time = currentDate.getTime() - storedDate.getTime()
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

      if (Difference_In_Days >= 30) {
        localStorage.removeItem('timestamp')
        localStorage.removeItem('quote')
        localStorage.removeItem('steps')
        localStorage.removeItem('progress')
        localStorage.removeItem('deletedSchools')
        navigate('/')
        return
      }
    } else {
      navigate('/')
      return
    }

    let retrievedQuote = await localStorage.getItem('quote')
    if (retrievedQuote !== null) {
      let bytesQuote = CryptoJS.AES.decrypt(retrievedQuote, token)
      let decryptedQuote = JSON.parse(bytesQuote.toString(CryptoJS.enc.Utf8))
      setQuote(decryptedQuote)
    }

    let retrievedSteps = await localStorage.getItem('steps')
    if (retrievedSteps !== null) {
      let bytesSteps = CryptoJS.AES.decrypt(retrievedSteps, token)
      let decryptedSteps = JSON.parse(bytesSteps.toString(CryptoJS.enc.Utf8))
      let currentStep = decryptedSteps.find((step) => step.current === true)

      switch (currentStep.id) {
        case 0:
          navigate('/')
          break
        case 1:
          navigate('/step1')
          break
        case 2:
          navigate('/step2')
          break
        case 3:
          navigate('/step3')
          break
        case 4:
          navigate('/step4')
          break
        case 5:
          navigate('/complete')
          break
        default:
          navigate('/')
          break
      }
      setSteps(decryptedSteps)
    } else {
      navigate('/')
    }

    let retrievedProgress = await localStorage.getItem('progress')
    if (retrievedProgress !== null) {
      let bytesProgress = CryptoJS.AES.decrypt(retrievedProgress, token)
      let decryptedProgress = JSON.parse(
        bytesProgress.toString(CryptoJS.enc.Utf8)
      )
      setProgress(decryptedProgress)
    }

    let retrievedSchools = await localStorage.getItem('deletedSchools')
    if (retrievedSchools !== null) {
      let bytesSchools = CryptoJS.AES.decrypt(retrievedSchools, token)
      let decryptedSchools = JSON.parse(
        bytesSchools.toString(CryptoJS.enc.Utf8)
      )
      setDeletedSchools(decryptedSchools)
    }
  }

  const goToStep = (newStep, status = '') => {
    const tempSteps = [...steps]
    tempSteps.forEach((step) => {
      step.current = false
    })
    tempSteps[newStep].current = true
    if (status !== '' && newStep > 0) {
      tempSteps[newStep - 1].status = status
    }

    if (status === 'complete') {
      tempSteps[newStep].status = 'in-progress'
    }
    setSteps(tempSteps)
    if (newStep === 0) {
      navigate('/')
    } else if (newStep === 5) {
      navigate('/complete')
      if (tempSteps[5].status !== 'complete') {
        createQuote()
      }
    } else {
      navigate('/step' + newStep)
    }
    saveProgress()
    if (newStep > 1) {
      updateEnquiryStage(newStep)
    }
  }

  const updateEnquiryStage = async (step) => {
    if (step === 6) {
      step = 'complete'
    }
    let nahtMember = 0
    // if (quote.marketing.nahtMember === 'true') {
    //   nahtMember = 1
    // }
    // console.log(nahtMember)
    try {
      await axios.put(api + 'enquiry/' + progress.enquiryID, {
        token: token,
        enquiry_callback: progress.callback,
        enquiry_stage: step,
        enquiry_reference: 1,
        enquiry_marketing: quote.marketing,
        quote_id: progress.quoteID,
        naht_member: nahtMember,
      })
    } catch (error) {}
  }

  const [quoteStatus, setQuoteStatus] = useState()

  const createQuote = async () => {
    setQuoteStatus('Generating Your Quote...')
    try {
      const response = await axios.get(api + 'quote/sum', { token: token })
      if (response.data.data) {
        let quoteNumber = response.data.data
        let finalQuoteReference = 'SAS' + quote.ref + '-' + quoteNumber

        let currentdate = new Date()
        let datetime =
          currentdate.getDate() +
          '/' +
          (currentdate.getMonth() + 1) +
          '/' +
          currentdate.getFullYear() +
          ' @ ' +
          currentdate.getHours() +
          ':' +
          currentdate.getMinutes() +
          ':' +
          currentdate.getSeconds()
        let tempMeta = { ...quote.meta }
        tempMeta.endDateTime = datetime
        setQuote({ ...quote, meta: tempMeta })

        try {
          const response2 = await axios.post(api + 'user/enquiry', {
            token: token,
            enquiry_id: progress.enquiryID,
          })

          if (response2.data.data) {
            progress.userID = response2.data.data

            const tempSchools = [...quote.schools]
            for (var i = 0; i < tempSchools.length; i++) {
              //now loop round all the items
              for (var n = 0; n < tempSchools[i].items.length; n++) {
                //now loop round all the items
                if (tempSchools[i].items[n].maternity === '') {
                  tempSchools[i].items[n].maternity = '0.00'
                }
              }
            }

            quote.schools = tempSchools
            quote.referenceNumber = finalQuoteReference
            quote.referralCode = progress.marketing.referralCode

            setQuote({ ...quote, referenceNumber: finalQuoteReference })

            await localStorage.removeItem('quote')
            await localStorage.removeItem('progress')
            await localStorage.removeItem('deletedSchools')
            await localStorage.removeItem('steps')

            let quoteJSON = JSON.stringify(quote)
            setQuoteStatus('Saving Your Quote...')
            try {
              const response3 = await axios.post(api + 'quote', {
                token: token,
                quote: quoteJSON,
                enquiry: 1,
              })
              if (response3.data.data) {
                setProgress({ ...progress, quoteID: response3.data.data })
                setQuoteStatus('Quote Complete')

                try {
                  await axios.put(api + 'enquiry/' + progress.enquiryID, {
                    token: token,
                    enquiry_callback: progress.callback,
                    enquiry_stage: 'complete',
                    enquiry_reference: finalQuoteReference,
                    quote_id: response3.data.data,
                    footer: 'sas',
                  })

                  const tempSteps = [...steps]
                  tempSteps[5].status = 'complete'
                  setSteps(tempSteps)
                } catch (error) {}
              }
            } catch (error) {}
          }
        } catch (error) {}
      }
    } catch (error) {}
  }

  useEffect(() => {
    console.log(window.location.pathname)
    if (
      window.location.pathname == '/' ||
      window.location.pathname == '/step1' ||
      window.location.pathname == '/step3' ||
      window.location.pathname == '/step4' ||
      window.location.pathname == '/step2' ||
      window.location.pathname == '/complete'
    ) {
      loadProgress()
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        steps,
        progress,
        setProgress,
        quote,
        setQuote,
        saveProgress,
        goToStep,
        api,
        token,
        quoteStatus,
        setQuoteStatus,
        sidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
